import React from "react";
import { Table } from "antd";
import config from "../../config";

const honorHost = config.honorHost

class Honor extends React.Component{
    constructor(props) {
        super(props);
        this.getUserMessage()
    }

	getUserMessage(){
		fetch("honorApi/dingTalkUserMgr/getUserInfo/"+this.props.user.userid,{
			method: 'GET',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
				'Access-Control-Allow-Origin': '*'
			},
			mode: 'cors',
		}) .then(res => res.json())
		.then((res)=>{
			if(res.data.occLoginId!=null){
				this.getIntegral(res.data.occLoginId)	
			}

		})
	}


	getIntegral(occLoginId){
		fetch("honorApi/dingTalkCreditsMgr/getPersonalGrade/"+occLoginId+"/"+this.state.page+"/"+this.state.size,{
			method: "GET",
			headers: {
			    'Content-Type': 'application/json; charset=utf-8',
			    'Access-Control-Allow-Origin': '*'
			},
			mode: "cors",
			
		})
		.then(res=>res.json())
		.then(res=>{
		    if (res.code == 20000){
		        this.setState({
		            data: res.data?.list
		        })
		    }
		})
		
	}

    state = {
        column: [
			{
			    title:"姓名",
			    dataIndex: 'name',
			    key: 'name',
			    align: 'left',
			},
            {
                title:"主要内容",
                dataIndex: 'reason',
                key: 'reason',
                align: 'left',
            },
			{
			    title:"兑换前积分",
			    dataIndex: 'gradeBefore',
			    key: 'gradeBefore',
			    align: 'left',
			},
            {
                title:"积分变化",
                dataIndex: 'points',
                key: 'points',
                align: 'left',
            },
			{
			    title:"兑换后积分",
			    dataIndex: 'gradeAfter',
			    key: 'gradeAfter',
			    align: 'left',
			},
            {
                title:"时间",
                dataIndex: 'createTime',
                key: 'createTime',
                align: 'left',
            }
        ],
        data: [],
		size:10,
		page:1,
    }

    render() {
        return (
            <div className={"honor"}>
                <div style={{flex:1}}></div>
                <div className={"user-list"}>
                    <Table dataSource={this.state.data} columns={this.state.column} pagination={{showSizeChanger: true,  showQuickJumper: true, showTotal: (count=this.data.total)=>{ return '共'+count+'条数据'},pageSizeOptions:['5','10','15','20']}}/>
                </div>
                <div style={{flex:1}}></div>
            </div>
        );
    }

}

export default Honor
