import React from 'react';
import { Row, Col } from 'antd';
import H5AppQSUser from './user.js'
import TopMenu from './topMenu/topMenu';

class H5AppQSHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.props;
    }
	
	//判断是手机端还是移动端
	componentDidMount() {
		if (
		  navigator.userAgent.match(/Android/i) ||
		  navigator.userAgent.match(/ios/i) ||
		  navigator.userAgent.match(/webOS/i) ||
		  navigator.userAgent.match(/iPhone/i) ||
		  navigator.userAgent.match(/iPad/i) ||
		  navigator.userAgent.match(/iPod/i) ||
		  navigator.userAgent.match(/BlackBerry/i) ||
		  navigator.userAgent.match(/Windows Phone/i)
		) {
		  this.setState(
			{isPhone:true}
		  );
		  // 移动端适配
		 
	
		} else {
		  this.setState(
		  {isPhone:false}
		  );
		}
	  }
	  
	  state = {
	  	isPhone:false,
	  }

    render() {
        const {location,change} = this.state;
        return (
            <div>
				{this.state.isPhone?(
				
					<div>
						<Row>
							<Col xs={{ span: 24 }}
								sm={{ span: 24 }}
								md={{ span: 24 }}
								lg={{ span: 24 }}
								xl={{ span: 24 }}
								xxl={{ span: 24 }}
							><TopMenu current={location.pathname} change={change} ></TopMenu>
							</Col>
						</Row>
					</div>
					
				):(
					<div>
						<Row>
							<Col xs={{ span: 0 }}
								sm={{ span: 0 }}
								md={{ span: 0 }}
								lg={{ span: 0 }}
								xl={{ span: 5 }}
								xxl={{ span: 3 }}>
							<a href="/index.html">
								<img src="/images/logo.png" alt="dingding" className="logo"/>
								<span className="logo">资产中心</span>
							</a>
							</Col>
							<Col xs={{ span: 16 }}
								sm={{ span: 16 }}
								md={{ span: 14 }}
								lg={{ span: 14 }}
								xl={{ span: 10 }}
								xxl={{ span: 11 }}
							><TopMenu current={location.pathname} change={change}></TopMenu>
							</Col>
							<Col xs={{ span: 8 }}
								sm={{ span: 5 }}
								md={{ span: 5 }}
								lg={{ span: 7 }}
								xl={{ span: 6 }}
								xxl={{ span: 7 }}
							><H5AppQSUser style={{ float: 'right' }} user={this.props.user}/>
							</Col>
							<Col style={{"color":"white","textAlign":"left"}} xs={{ span: 0 }}
								 sm={{ span: 3 }}
								 md={{ span: 5 }}
								 lg={{ span: 3 }}
								 xl={{ span: 3 }}
								 xxl={{ span: 3 }}>
								(<span>{this.props.user.userid}</span>)
							</Col>
						</Row>       
					</div>
				)}
			
			</div>
        );
    }
}

export default H5AppQSHeader
