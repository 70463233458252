import React from "react";
import {Menu} from 'antd';
import {Link} from "react-router-dom";
import './topMenu.css'

class topMenu extends React.Component {
    state = {
        current: this.props.current
    };

    handleClick = e => {
        this.props.change(e)
        this.setState({ current: e.key });
        this.setState({ current: e.key });
    };

    render() {
        const { current } = this.state
        return (
            <Menu theme="dark" onClick={this.handleClick} selectedKeys={[current]} mode="horizontal">
                <Menu.Item key="/handle">
                    <Link to="/handle">主页</Link>
                </Menu.Item>
                <Menu.Item key="honor">
                    <Link to="/honor">积分记录</Link>
                </Menu.Item>
                <Menu.Item key="integral">
                    <Link to="/integral">积分商城</Link>
                </Menu.Item>
            </Menu>
        );
    }
}

export default topMenu
