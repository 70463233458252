import React from "react";
import { Table } from "antd";
import config from "../../config";
import "./handle.css";

// const honorHost = config.honorHost

// const goodsHost = config.goodsHost



class Handle extends React.Component{
	
    constructor(props) {
        super(props);
        this.getUserInfo()
    }

	//用户当前积分查询
    getUserInfo() {
		 fetch("honorApi/dingTalkUserMgr/getUserInfo/"+this.props.user.userid,{
        // fetch(honorHost+"/dingTalkUserMgr/getUserInfo/"+this.props.user.userid,{
			method: "GET",
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'Access-Control-Allow-Origin': '*'
            },
            mode: "cors",
        })	
            .then(res=>res.json())
            .then(res=>{
                if (res.code == 20000){
                    this.setState({
						grade:res.data.grade,
						
                    })
					
					this.activeStart()
                }
            })
    }
	
	//活动时间查询
	activeStart(){
		fetch("shopGoodsApi/goods/getActivity",{
		// fetch(goodsHost+"/goods/getActivity",{
			method: "GET",
		    headers: {
		        'Content-Type': 'application/json; charset=utf-8',
		        'Access-Control-Allow-Origin': '*'
		    },
		    mode: "cors",
		})	
		    .then(res=>res.json())
		    .then(res=>{
		        if (res.code == 20000){
					if(res.data==null){
						this.setState({
						   isExit:false
						})
					}else{
						if(res.data.img!=null){
							this.setState({
								goodsName: res.data.goodsName,
								img:res.data.img,
								startTime:res.data.startTime,
								endTime:res.data.endTime,
								detail:res.data.detail,
								isExit:true,
								isImage:true,
							})
							}else{
							this.setState({
								goodsName: res.data.goodsName,
								img:res.data.img,
								startTime:res.data.startTime,
								endTime:res.data.endTime,
								detail:res.data.detail,
								isExit:true,
								isImage:false,
							})
						}
					}
				}
		    })
	}

	//判断是手机端还是移动端
	componentDidMount() {
		if (
		  navigator.userAgent.match(/Android/i) ||
		  navigator.userAgent.match(/webOS/i) ||
		  navigator.userAgent.match(/iPhone/i) ||
		  navigator.userAgent.match(/iPad/i) ||
		  navigator.userAgent.match(/iPod/i) ||
		  navigator.userAgent.match(/BlackBerry/i) ||
		  navigator.userAgent.match(/Windows Phone/i)
		) {
		  this.setState(
			{isPhone:true}
		  );
		  // 移动端适配
		 

		} else {
		  this.setState(
		  {isPhone:false}
		  );
		}
	  }
    state = {
		goodsName:"",
		grade:"",
		img:"",
		startTime:"",
		endTime:"",
		detail:"",
		isExit:false,
		isImage:false,
		isPhone:false,
    }

    render() {
        return (
			<div className={this.state.isPhone ? 'iphHandle':'handle'}>
				<div className={this.state.isPhone ? 'iphDiv1':'div1'}>
					<text className={"fontStyle1"}>当前用户积分：</text>	
					<text className={"fontStyle"}>{this.state.grade}</text>
				</div>
				{this.state.isExit?(
					<div className={this.state.isPhone ? 'iphDiv2':'div2'}>
						<div>
							<text className={"fontStyle1"}>{this.state.goodsName}</text>
							<div style={{"margin":"0 0 10px 0"}}>
								<text>{this.state.detail}</text>
							</div>
							{this.state.isImage?(
								<div>
									<img src={`data:image/png;base64,${this.state.img}`} className={this.state.isPhone ? 'iphImage':'divImage'}/>
								</div>
								):''}
							<div className={this.state.isPhone ? 'textStyle':'textStyle1'}>
								<text className={"fontStyle1"}>活动开始时间：</text>
								<text className={"fontStyle"}>{this.state.startTime}</text>
							</div>
							<div className={this.state.isPhone ? 'textStyle':'textStyle1'}>
								<text className={"fontStyle1"}>活动结束时间：</text>
								<text className={"fontStyle"}>{this.state.endTime}</text>
							</div>
						</div>
					</div>):(
					<div className={"div3"}>
						<div style={{"margin":"10% 0 30px 0"}}>							
							<img src={require('../../noImage.png')} height="60%" width="40%"/>
						</div>
						<h3>暂无活动数据</h3>
					</div>)
				}
				
			</div>
           
        );
    }
}

export default Handle
