const config = {
    // yarn start 本地调试时由于默认为3000端口
    // 访问后端host请配置为本机IP的backend服务端口，如8080端口
    // 例如 host: 'http://192.168.1.2:8080',
    // 使用backend中构建结果访问时请将host指定为空
    host: 'http://127.0.0.1:18099',
    honorHost: "http://127.0.0.1:18096",
	goodsHost: 'http://127.0.0.1:18097',
}

export default config;
