import React from 'react';
import { HashRouter as Router, Switch, Route, Link, useParams, withRouter } from 'react-router-dom';
import { Layout, Row, Col, Menu, Icon, Card } from 'antd';
import H5AppQSHeader from './components/header.js'
import Honor from "./components/honor/honor";
import H5AppQS from "./H5AppQS";
import H5AppQSDeplist from './components/deplist.js'
import * as dd from 'dingtalk-jsapi';

import './App.css';

const PlatformDetail = function(props) {
    return (
        <Card title="平台详情" style={{ width: 400, margin: '15px auto' }}>
            <Row gutter={[16,8]}>
                <Col span={8} style={{ textAlign: "right" }}>平台:</Col>
                <Col span={14} style={{ textAlign: "left" }}>{props.env.platform}</Col>
            </Row>
            {
                'version' in props.env && props.env.version &&
                <Row gutter={[16,8]}>
                    <Col span={8} style={{ textAlign: "right" }}>版本:</Col>
                    <Col span={14} style={{ textAlign: "left" }}>{props.env.version}</Col>
                </Row>
            }
            <Row gutter={[16,8]}>
                <Col span={8} style={{ textAlign: "right" }}>应用类型:</Col>
                <Col span={14} style={{ textAlign: "left" }}>{props.env.appType}</Col>
            </Row>
            <Row gutter={[16,8]}>
                <Col span={8} style={{ textAlign: "right" }}>语言:</Col>
                <Col span={14} style={{ textAlign: "left" }}>{props.env.language}</Col>
            </Row>
        </Card>
    )
}

const alert = function(msg) {
    dd.device.notification.alert({
        message: msg,
        title: "提示",//可传空
        buttonName: "确定",
        onSuccess : function() {
        },
        onFail : function(err) {}
    }).catch((err) => {
        window.alert(msg);
    });
}

function DeptList(props) {
    let { deptId } = useParams();
    return <H5AppQSDeplist deptId={deptId} config={props.config}/>
}

function App(props) {
    return (
        <Router>
            <div className="App">
                <H5AppQS config={props.config}/>
            </div>
        </Router>
    );
}

export default App;
