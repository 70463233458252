import React from "react";
import * as dd from "dingtalk-jsapi";
import {Layout} from "antd";
import H5AppQSHeader from "./components/header";
import {Route, Switch, withRouter,Redirect} from "react-router-dom";
import Honor from "./components/honor/honor";
import config from "./config";
import Handle from "./components/handle/handle";
import ShopMall from "./components/shopMall/shopMall";

const { Header, Content, Footer } = Layout;

class H5AppQS extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            env: dd.env,
            user: {
                name: "用户名",
                userid: "userid",
                avatar: null,
            },
            authCode: null,
            config: {
                corpId: null,
                agentId: null
            },
            current: 'home',
            location: {
                pathname:this.props.location.pathname!=="/"?this.props.location.pathname.slice(1):'/'
            }
        };
    }

    componentDidMount() {
        // 设置导航栏标题
        dd.biz.navigation.setTitle({
            title : '资产中心',//控制标题文本，空字符串表示显示默认文本
            onSuccess : function(result) {},
            onFail : function(err) {}
        }).catch(err => {console.log(err + '')});

        const that = this
        fetch('hostApi/config', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
               'Access-Control-Allow-Origin': '*'
            },
            mode: 'cors',
            body: 'url='+window.location.href.replace(window.location.hash, "")
        })
            .then(res => res.json())
            .then(
                (result) => {
                    that.setState({
                        config: {
                            ...result.result
                        }
                    })
					
                    dd.runtime.permission.requestAuthCode({
                        corpId: that.state.config.corpId,
                        onSuccess: function (info) {
                            that.setState({
                                authCode: info.code,
                            })
				
                            fetch( 'hostApi/login', {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                                    'Access-Control-Allow-Origin': '*'
                                },
                                mode: 'cors',
                                body: 'authCode='+info.code,
                            })
                                .then(res => res.json())
                                .then((result) => {
                                    that.setState({
                                        user: {
                                            ...result.result
                                        }
                                    })
                                });
						

                        },
                        onFail: function (err) {
                            alert('免登授权码获取失败: ' + JSON.stringify(err));
                        }
                    }).catch(err => {console.log(err + '')});
                }
            )
            .catch((err) => {
                alert('获取JS鉴权信息失败: ' + JSON.stringify(err));
            });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.location.pathname !== nextProps.location.pathname) {
            this.state.location.pathname=nextProps.location.pathname!=="/"?nextProps.location.pathname.slice(1):'/';
        }
    }


    change = e => {
        this.setState({
            current: e.key,
        });
    }
	
    render() {
        const {location,user} = this.state
        return (
            <div>
                <Layout>
                    <Header><H5AppQSHeader location={location} change={this.change} user={this.state.user}/></Header>
                    <Content>
                        <Switch>
                            <Route path="/honor">
                                <Honor user={user}></Honor>
                            </Route>
							<Route path="/integral">
								<ShopMall user={user}></ShopMall>
							</Route>
                            <Route path="/handle">
								<Handle user={user}></Handle>
                            </Route>
                        </Switch>
                    </Content>
                    <Footer>@贝尔康医药</Footer>
                </Layout>
            </div>
        )
    }
}

export default withRouter(H5AppQS)
