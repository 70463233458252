import React from "react";
import { Modal, Row, Table } from "antd";
import config from "../../config";
import { isImageUrl } from "antd/lib/upload/utils";
import "../shopMall/shopMall.css"

class ShopMall extends React.Component{
    constructor(props) {
        super(props);
        this.getGoodsInfo()
    }

    getGoodsInfo() {
		 fetch("shopGoodsApi/goods/getGoodsPage/",{
			method: "POST",
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'Access-Control-Allow-Origin': '*'
            },
            mode: "cors",
			body:JSON.stringify({
				'pageNo':this.state.pageNo,
				'pageSize':this.state.pageSize,
				'goodsName':this.state.inputValue,
				'status':[1],
			})
        })	
            .then(res=>res.json())
            .then(res=>{
                if (res.code == 20000){
                    this.setState({
						data:res.data,
                        results: res.data?.list,
						
                    })
                }
            })
    }
	
	/**
	 * 用户信息获取
	 */
	userMessage(id){
		fetch("honorApi/dingTalkUserMgr/getUserInfo/"+this.props.user.userid,{
			method: 'GET',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
				'Access-Control-Allow-Origin': '*'
			},
			mode: 'cors',
		}) .then(res => res.json())
		.then((res)=>{
			 this.setState({
				occLoginId:res.data.occLoginId
			 })
			 this.goodsClick(id,res.data.occLoginId,res.data.dingLoginId)
		})
	}
	
	/**
	 * 商品兑换
	 */
	goodsClick(id,occLoginId,dingLoginId){
		fetch("shopGoodsApi/goods/getActivity",{
			method: "GET",
		    headers: {
		        'Content-Type': 'application/json; charset=utf-8',
		        'Access-Control-Allow-Origin': '*'
		    },
		    mode: "cors",
		})	
		    .then(res=>res.json())
		    .then(res=>{
		        if (res.code == 20000){
					let date = new Date().getTime();
					let startTime = new Date(res.data.startTime).getTime();
					let endTime = new Date(res.data.endTime).getTime();
					if((date>startTime)&&(date<endTime)){
						fetch("shopGoodsApi/goods/forGoods",{
							method: "POST",
							headers: {
								'Content-Type': 'application/json; charset=utf-8',
								'Access-Control-Allow-Origin': '*'
							},
							mode: "cors",
							body:JSON.stringify({
								id:id,
								num:1,
								operationOccLoginId:occLoginId,
								operatorDingLoginId:dingLoginId,
								type:1,
								occLoginId:occLoginId,
							})
						})	
						.then(res=>res.json())
						.then(res=>{
							alert(JSON.stringify(res.message))
						})	
					}else{
						alert("不在兑换时间内，无法进行兑换")
					}
		        }
		    })
	}
	/**
	 * 商品信息输入取值
	 */
	inputChange(e){
		let val=this.refs.goodsMessage.value;
		this.setState({
			inputValue:val
		})
	}
	//判断是移动端还是PC端
	componentDidMount() {
		if (
		  navigator.userAgent.match(/Android/i) ||
		  navigator.userAgent.match(/webOS/i) ||
		  navigator.userAgent.match(/iPhone/i) ||
		  navigator.userAgent.match(/iPad/i) ||
		  navigator.userAgent.match(/iPod/i) ||
		  navigator.userAgent.match(/BlackBerry/i) ||
		  navigator.userAgent.match(/Windows Phone/i)
		) {
		  this.setState(
			{isPhone:true}
		  );
		  // 移动端适配
		} else {
		  this.setState(
		  {isPhone:false}
		  );
		}
	  }
    
	modalOpen(record){
		console.log(record)
		this.setState({
			showModal:true,
			modalImage:'data:image/png;base64,'+record.img
		})
		console.log(this.state.showModal,111)
	}
	
	
	onOk = () => {
	      this.setState({
	      	showModal:false,
	      })
	    }
	
	onCancel = () =>{
	    this.setState({
	    	showModal:false,
	    })
	  }
	  
	
	state = {
		isPhone:false,
		occLoginId:null,
		column: [
			{
				title: '商品图片',
				dataIndex: 'img',
				key: 'img',
				width:300,
				render:(img)=> <img src={`data:image/png;base64,${img}`} width="200px" height="100px"/>
			},
		    {
		        title:"商品名称",
		        dataIndex: 'goodsName',
		        key: 'goodsName',
		        align: 'left',
				width:200,
		    },
			{
			    title:"现有数量",
			    dataIndex: 'store',
			    key: 'store',
			    align: 'left',
				width:200,
			},
		    {
		        title:"兑换积分",
		        dataIndex: 'point',
		        key: 'point',
		        align: 'left',
				width:200,
		    },
			{
			    title:"限购",
			    dataIndex: 'limit',
			    key: 'limit',
			    align: 'left',
				width:200,
			},
			{
			    title:"操作",
			    dataIndex: 'id',
			    align: 'left',
				width:200,
				render:(id)=><button width="100px"  className={"buttonStytle"} onClick={(e)=>{e.stopPropagation();this.userMessage(id)}}>兑换</button>
				},
		],
        iphColumn:[
			{
			    title:"商品名称",
			    dataIndex: 'goodsName',
			    key: 'goodsName',
			    align: 'left',
				width:200,
			},
			{
			    title:"现有数量",
			    dataIndex: 'store',
			    key: 'store',
			    align: 'left',
				width:200,
			},
			{
			    title:"兑换积分",
			    dataIndex: 'point',
			    key: 'point',
			    align: 'left',
				width:200,
			},
			{
			    title:"限购",
			    dataIndex: 'limit',
			    key: 'limit',
			    align: 'left',
				width:200,
			},
			{
			    title:"操作",
			    dataIndex: 'id',
			    align: 'left',
				width:200,
				render:(id)=><button width="100px"  className={"buttonStytle"} onClick={(e)=>{e.stopPropagation();this.userMessage(id)}}>兑换</button>
			},
		],
		results:[],
		data:"",
		resultMessage:"",
		inputValue:"", 
		showModal:false,
		modalImage:null,
    }
	
    render() {
        return (
			
			<div className={"shopMall"}>
				<div className={this.state.isPhone ? 'queryGoods':'queryGoods1'} >
					<input type="text" className={"input"} onChange={(e)=>this.inputChange()} ref='goodsMessage'/> 
					<button className={"button"} onClick={(e)=>this.getGoodsInfo()}>搜索</button>
				</div>
				<Table dataSource={this.state.results} columns={this.state.isPhone ? this.state.iphColumn:this.state.column} onRowClick={ this.state.isPhone ? (record)=>this.modalOpen(record) :''} pagination={{showSizeChanger: true,  showQuickJumper: true, showTotal: (count=this.data.total)=>{ return '共'+count+'条数据'},pageSizeOptions:['5','10','15','20']}}/>
				<Modal
				          visible={this.state.showModal}
				          onCancel={this.onCancel}
				          // onOk={this.onOk}
						  footer={null}
				        >
						<img src={this.state.modalImage} width="100%" height="100%"/>
				</Modal>
			</div>
        );
	 
	}
}

export default ShopMall
